import moment from 'moment'
import { debounce } from 'lodash'
import danmo_small from '@/assets/img/map/device-danmo.png'
import duomo_small from '@/assets/img/map/device-duomo.png'
import lora_small from '@/assets/img/map/device-lora.png'
import { useMapStore } from '@/stores/map'
import { MAP_TYPE_ENUM, PRODUCT_TYPE } from '@/common/enum'
import i18n from '@/i18n'
import L from 'leaflet'

const t = i18n.global.t

const mapStore: any = useMapStore()

// icon
export const iconMap: any = {
  [PRODUCT_TYPE.SINGLE_GATEWAY]: danmo_small,
  [PRODUCT_TYPE.MULTI_GATEWAY]: duomo_small,
  [PRODUCT_TYPE.LORA]: lora_small,
}

export const getDeviceIcon = (code: string) => {
  return iconMap[code]
}

export const iconClaseeMap: any = {
  [PRODUCT_TYPE.SINGLE_GATEWAY]: 'ws_icon_danmo',
  [PRODUCT_TYPE.MULTI_GATEWAY]: 'ws_icon_duomo',
  [PRODUCT_TYPE.LORA]: 'ws_icon_lora',
}
export const getDeviceClass = (code: string) => {
  return iconClaseeMap[code]
}

export const layerConf = [
  {
    url: 'https://tiles1.geovisearth.com/base/v1/img/{z}/{x}/{y}?format=webp&tmsIds=w&token=',
    name: t('COMMON.satelliteMap'),
    key: MAP_TYPE_ENUM.STAR,
  },
  {
    url: 'https://tiles1.geovisearth.com/base/v1/vec/{z}/{x}/{y}?format=png&tmsIds=w&token=',
    name: t('COMMON.streetMap'),
    key: MAP_TYPE_ENUM.STAND,
  },
  {
    url: 'https://tiles1.geovisearth.com/base/v1/ter/{z}/{x}/{y}?format=png&tmsIds=w&token=',
    name: t('COMMON.terrainMap'),
    key: MAP_TYPE_ENUM.TERRAIN,
  },
]

export const createLayer = () => {
  return layerConf.map(({ url, name, key }: any) => {
    return {
      instance: L.tileLayer(url, {
        attribution: 'stamen',
      }),
      name,
      key,
    }
  })
}

export const createMap = (
  id: string,
  options: any = {
    tileLayerType: MAP_TYPE_ENUM.STAR,
  }
) => {
  if (!id) {
    console.error('缺少id')
  }

  const map = new L.Map(id, {
    center: new L.LatLng(34.16793, 107.3863),
    zoom: 10,
    zoomControl: false,
    attributionControl: false,
    ...options,
  })

  const layerConfIndex = layerConf.findIndex(
    (item) => item.key === options.tileLayerType
  )

  const layerConfTarget = layerConf[layerConfIndex].url + mapStore.tk

  const layerInstance: any = L.tileLayer(layerConfTarget, {
    minZoom: 1,
    noWrap: true,
  })
  mapStore.setLayer(layerInstance)

  mapStore.clearBaseLayer()
  const baseLayerInstance = mapStore.getBaseLayer()
  baseLayerInstance.addTo(map)

  layerInstance.addEventListener('tileerror', debounceTk)

  layerInstance.addTo(map)

  mapStore.setMap(map)

  const corner1 = L.latLng(-90, -180) //设置左上角经纬度
  const corner2 = L.latLng(90, 180) //设置右下点经纬度
  const bounds = L.latLngBounds(corner1, corner2) //构建视图限制范
  map.setMaxBounds(bounds)

  const scale = L.control.scale({ metric: true, imperial: false })
  scale.addTo(map)

  return map
}

const debounceTk = debounce((error: any) => {
  console.error('================= 瓦片加载错误 =================')
  // mapStore.validateTk(error.target._url)
}, 500)

export const createPopup = (device: any) => {
  return new L.popup({
    offset: L.point(0, -41),
  })
}

// 单位展示
export const formatterKm = (m: any) => {
  const num = Number(m)
  if (num > 1000) {
    return (num / 1000).toFixed(2) + 'km'
  }
  return num.toFixed(2) + 'm'
}

export const formatLat = (lat: any) => {
  return lat > 0 ? 'N' : 'S'
}

export const formatLng = (lng: any) => {
  return lng > 0 ? 'E' : 'W'
}

// 校验是否为度分秒
export const validateDMS = (dmsString: string) => {
  const dmsPattern = /^-?(\d{1,3})°\s*(\d{1,2})'\s*(\d{1,2}(\.\d+)?)?"?$/
  const match = dmsString.match(dmsPattern)

  if (!match) {
    return false
  }

  const degrees = parseInt(match[1], 10)
  const minutes = parseInt(match[2], 10)
  const seconds = parseFloat(match[3])

  // 校验度、分、秒的范围
  if (
    degrees < 0 ||
    degrees > 180 ||
    minutes < 0 ||
    minutes >= 60 ||
    seconds < 0 ||
    seconds >= 60
  ) {
    return false
  }

  return true
}

// 度分秒转小数
export const DMSToDecimal = (dmsString: string) => {
  const dmsPattern = /^-?(\d{1,3})°\s*(\d{1,2})'\s*(\d{1,2}(\.\d+)?)?"?$/
  const match = dmsString.match(dmsPattern)

  if (!match) {
    throw new Error('Invalid DMS format')
  }

  const degrees = parseInt(match[1], 10)
  const minutes = parseInt(match[2], 10)
  const seconds = parseFloat(match[3])
  const decimal = degrees + minutes / 60 + seconds / 3600
  return decimal.toFixed(6).toString()
}

// 小数转度分秒
export const decimalToDMS = (decimal: any) => {
  const isNegative = decimal < 0
  decimal = Math.abs(decimal)

  const degrees = Math.floor(decimal)
  const minutesFloat = (decimal - degrees) * 60
  const minutes = Math.floor(minutesFloat)
  const seconds = (minutesFloat - minutes) * 60

  return `${isNegative ? '-' : ''}${degrees}° ${minutes}' ${seconds.toFixed(
    2
  )}"`
}
