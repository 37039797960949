import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { MAP_TK, MAP_PRO_TK } from '@/common/constant'
import axios from 'axios'
import qs from 'qs'
import L from 'leaflet'

// 生产环境优先使用企业账号，其他环境优先使用个人账号
const TK_LIST =
  process.env.VUE_APP_ENV === 'production'
    ? MAP_PRO_TK.concat(MAP_TK)
    : MAP_TK.concat(MAP_PRO_TK)

export const useMapStore = defineStore('map', () => {
  const layerInstance: any = ref(null)

  const map: any = ref(null)

  const setLayer = (value: any) => {
    layerInstance.value = value
  }

  const setMap = (value: any) => {
    map.value = value
  }

  const tk = ref(TK_LIST[0])

  const changeTK = (preTK?: string) => {
    if (preTK !== tk.value) {
      return
    }
    const index = TK_LIST.indexOf(tk.value)
    const moIndex = index >= TK_LIST.length - 1 ? 0 : index + 1
    tk.value = TK_LIST[moIndex]
    console.error(`================= 开始替换TK ${tk.value} =================`)
    // 重置底图
    getBaseLayer()
  }

  const validateTk = async (requestUrl: string) => {
    const url = requestUrl.split('?')[1]
    const mo: any = qs.parse(url)

    if (mo.tk !== tk.value) {
      return
    }
    console.error(`================= 校验TK ${mo.tk} =================`)
    axios.get(requestUrl).catch((error: any) => {
      if (error?.response?.status === 429) {
        console.error(
          `================= 校验TK ${mo.tk} 请求次数太多 =================`
        )
        changeTK(mo.tk)
      }
    })
  }

  const getLanBaseLayer = () => {
    const wx_lan = localStorage.getItem('wx_lan') || 'cn'
    return wx_lan === 'cn'
      ? 'http://t0.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk='
      : 'http://t0.tianditu.gov.cn/eva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=eva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk='
  }

  const baseLayerInstance: any = ref(null)
  const getBaseLayer = () => {
    const url =
      'https://tiles1.geovisearth.com/base/v1/cia/{z}/{x}/{y}?format=png&tmsIds=w&token=' +
      tk.value
    if (baseLayerInstance.value) {
      baseLayerInstance.value.setUrl(url)
      return baseLayerInstance.value
    } else {
      const layer = L.tileLayer(url)
      layer.setZIndex(9999)
      baseLayerInstance.value = layer
      return layer
    }
  }

  const clearBaseLayer = () => {
    baseLayerInstance.value = null
  }

  return {
    layerInstance,
    setLayer,
    map,
    setMap,
    tk,
    changeTK,
    validateTk,
    getBaseLayer,
    clearBaseLayer,
  }
})
